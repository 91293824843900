import React from "react"
import { Link } from "gatsby"
import { Flex, Title } from "../components/system"

const NotFound = () => (
  <Flex height="75vh" flexDirection="column">
    <Title>Page Not Found</Title>
    <Link to="/">Go back home</Link>
  </Flex>
)

export default NotFound
